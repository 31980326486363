/* global $ */

$('body')
  .find('.image-slider')
  .slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

$('.latest-newsclips').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
});
