/* global $, TweenMax, TimelineMax, Power1, ScrollMagic */

const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss

// Static Animations, they will run once the site is loaded
const home = new TimelineMax();
home
  .to('.topper .container', 0, { opacity: 1 }, 0)
  .to('.overlay', 0.5, { opacity: 0.4, ease: Power1.easeInOut })
  .staggerFrom(
    '.topper h6, .interstitial__title, .interstitial__dek, .topper h1, .topper .deck',
    0.35,
    { opacity: 0, yPercent: 3, ease: Power1.easeInOut },
    0.18,
  )
  .staggerFrom(
    '.topper .video-link, .interstitial__cta, .topper .topper-cta a, .topper .donation',
    0.35,
    { opacity: 0, scale: 1.1, ease: Power1.easeInOut },
  )
  .staggerFrom('.topper form', 0.35, {
    opacity: 0,
    scale: 1.1,
    ease: Power1.easeInOut,
  })
  .staggerFrom('.interstitial__seco', 0.35, {
    opacity: 0,
    yPercent: 15,
    ease: Power1.easeInOut,
  })
  .staggerFrom('.topper .read-more', 0.35, {
    opacity: 0,
    yPercent: -15,
    scale: 1.05,
    ease: Power1.easeInOut,
  });
home.play();

// SCROLL MAGIC STARTS
const controller = new ScrollMagic.Controller();

if ($(window).width() >= animationBreakpoint) {
  if ($('.issues-grid').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.issues-grid',
      reverse: false,
      offset: 0,
      triggerHook: 1,
    })
      .setTween(
        TweenMax.staggerTo(
          '.issues-grid .single-issue',
          0.25,
          { opacity: 1 },
          0.15,
        ),
      )
      // .addIndicators()
      .addTo(controller);
  }

  if ($('.news-items').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.news-items',
      reverse: false,
      offset: 0,
      triggerHook: 1,
    })
      .setTween(
        TweenMax.staggerTo(
          '.news-items .news-item',
          0.25,
          { opacity: 1 },
          0.15,
        ),
      )
      // .addIndicators()
      .addTo(controller);
  }
}

if ($('#home_hero').length) {
  if ($('#introduction_section').length > 0) {
    let $offset = $(window).height() / -2;
    /* aca */
    new ScrollMagic.Scene({
      triggerElement: '#introduction_section',
      triggerHook: 'onLeave',
      duration: '900%',
    })
      .setClassToggle('.introduction__bg__effect', 'bg__enabled')
      .addTo(controller);

    var controller2 = new ScrollMagic.Controller({
      globalSceneOptions: { duration: '100%' },
    });
    // build scenes
    new ScrollMagic.Scene({ triggerElement: '.intro_one' })
      .setClassToggle('.introduction', 'color-intro-one')
      .addTo(controller2);
    new ScrollMagic.Scene({ triggerElement: '.intro_two' })
      .setClassToggle('.introduction', 'color-intro-two')
      .addTo(controller2);
    new ScrollMagic.Scene({ triggerElement: '.intro_three' })
      .setClassToggle('.introduction', 'color-intro-three')
      .addTo(controller2);
    new ScrollMagic.Scene({ triggerElement: '.intro_fourth' })
      .setClassToggle('.introduction', 'color-intro-fourth')
      .addTo(controller2);
    // this hides fourth block
    new ScrollMagic.Scene({
      triggerElement: '.grid-image',
      offset: $offset,
      duration: 100,
    })
      .setClassToggle('.introduction', 'color-intro-leave')
      .addTo(controller2);
    // this toggles visibility for texts in third block
    new ScrollMagic.Scene({
      triggerElement: '.intro_fourth--hide_prev',
      offset: $offset * 0.8,
      duration: 100,
    })
      .setClassToggle('.introduction', 'color-intro-prev-fourth')
      .addTo(controller2);
    // this toggles visibility for texts in fourth block
    new ScrollMagic.Scene({
      triggerElement: '.last_block',
    })
      .setClassToggle('.last_block', 'visible')
      .addTo(controller2);
    /* aca */

    function updateCount(e) {
      $('.stats').addClass('started');
      if ($('#counter-stats').length) {
        $('.count').each(function() {
          let count = $(this).attr('data-count');
          $(this)
            .prop('Counter', 0)
            .animate(
              {
                Counter: $(this).attr('data-count'),
              },
              {
                duration: 3000,
                easing: 'swing',
                step: function(now) {
                  $(this).text(Math.ceil(now).toLocaleString());
                },
              },
            );
        });
      }
    }
    if ($('#counter-stats').length) {
      new ScrollMagic.Scene({ triggerElement: '#counter-stats', offset: -60 })
        .on('enter', updateCount)
        .reverse(false)
        .addTo(controller);
    }
  }

  const cta = new TimelineMax();
  cta
    .staggerFrom(
      '.bottom-cta h6, .bottom-cta h1',
      0.35,
      { opacity: 0, yPercent: 3, ease: Power1.easeInOut },
      0.2,
    )
    .staggerFrom('.bottom-cta form', 0.45, {
      opacity: 0,
      scale: 1.08,
      ease: Power1.easeInOut,
    });

  if ($('.bottom-cta .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.bottom-cta .container',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(cta)
      // .addIndicators()
      .addTo(controller);
  }

  const img_actions = new TimelineMax();
  img_actions
    .to('.hero__right__first', 0.5, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      //transformOrigin: '0% 0%',
      ease: Power1.easeIn,
    })
    .to('.hero__left__first', 0.3, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      //transformOrigin: '100% 0%',
      ease: Power1.easeIn,
    })
    .to('.hero__right__second', 0.3, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      //transformOrigin: '0% 0%',
      ease: Power1.easeIn,
    })
    .to('.hero__left__second', 0.3, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      // transformOrigin: '100% 0%',
      ease: Power1.easeIn,
    })
    .to('.hero__right__third', 0.3, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      //transformOrigin: '0% 0%',
      ease: Power1.easeIn,
    })
    .to('.hero__left__third', 0.3, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      //transformOrigin: '100% 0%',
      ease: Power1.easeIn,
    })
    .to('.split-topper__main__headline span', 0.6, {
      scale: 1,
      backgroundSize: '100% 87%',
      ease: Power1.easeIn,
    });

  $('.button-scroll').click(function() {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('div.introduction').offset().top + 350,
      },
      200,
      'linear',
    );
  });

  var rect = $('#home_hero')[0].getBoundingClientRect();
  var mouse = { x: 0, y: 0, moved: false };

  $('#home_hero').mousemove(function(e) {
    mouse.moved = true;
    mouse.x = e.clientX - rect.left;
    mouse.y = e.clientY - rect.top;
  });

  // Ticker event will be called on every frame
  TweenLite.ticker.addEventListener('tick', function() {
    if (mouse.moved) {
      parallaxIt('.hero__right__first', -25);
      parallaxIt('.hero__right__second', -35);
      parallaxIt('.hero__right__third', -30);
      parallaxIt('.hero__left__first', -30);
      parallaxIt('.hero__left__second', -25);
      parallaxIt('.hero__left__third', -35);
    }
    mouse.moved = false;
  });

  function parallaxIt(target, movement) {
    TweenMax.to(target, 0.5, {
      x: ((mouse.x - rect.width / 2) / rect.width) * movement,
      y: ((mouse.y - rect.height / 2) / rect.height) * movement,
    });
  }

  $(window).on('resize scroll', function() {
    rect = $('#home_hero')[0].getBoundingClientRect();
  });
}
