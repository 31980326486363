/* global $ */

const $menuOverlay = $('body').find('.menuOverlay');
let lastScrollPosition = 0;

function openMenu(e) {
  lastScrollPosition = $('html, body').scrollTop();
  $menuOverlay.addClass('menu--open temp-high-z');
  e.preventDefault();
  $('html').addClass('noScroll overlay-active');
  $('html, body').animate({ scrollTop: 0 }, 50);
}

function closeMenu(e) {
  $('html').removeClass('noScroll overlay-active');
  $menuOverlay.removeClass('menu--open');
  e.preventDefault();
  e.stopPropagation();
  $('html, body').animate({ scrollTop: lastScrollPosition }, 50);
  setTimeout(() => {
    // eslint-disable-line
    $menuOverlay.removeClass('temp-high-z');
  }, 200);
}

$('body')
  .on('click', '#btn-menu', openMenu)
  .on('click', '#btn-menu-close', closeMenu);

// close on ESC
document.addEventListener('keyup', (ev) => ev.keyCode === 27 && closeMenu(ev));
