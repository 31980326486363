/* global $ */

// NGP
if ($('.ngp-code.add-callback').length > -1) {
  const ngpCallback = () => {
    $('.module.donation .ngp-code.add-callback').each(function() {
      const btnText = $(this)
        .find('.sr-only')
        .text();
      $(this)
        .find('label.FirstName')
        .addClass('show');
      $(this)
        .find('label.FirstName input')
        .attr('placeholder', 'First Name')
        .attr('tabindex', 1);
      $(this)
        .find('label.LastName')
        .addClass('show');
      $(this)
        .find('label.LastName input')
        .attr('placeholder', 'Last Name')
        .attr('tabindex', 2);
      $(this)
        .find('label.EmailAddress')
        .addClass('show');
      $(this)
        .find('label.EmailAddress input')
        .attr('placeholder', 'Your Email')
        .attr('tabindex', 3);
      $(this)
        .find('label.PostalCode')
        .addClass('show');
      $(this)
        .find('label.PostalCode input')
        .attr('placeholder', 'Zip Code')
        .attr('tabindex', 4);
      $(this)
        .find('.at-form-submit input')
        .attr('tabindex', 5)
        .prop('value', btnText);
    });
    $('.bottom-cta .ngp-code.add-callback').each(function() {
      const btnText = $(this)
        .find('.sr-only')
        .text();
      $(this)
        .find('label.FirstName')
        .addClass('show');
      $(this)
        .find('label.FirstName input')
        .attr('placeholder', 'First Name')
        .attr('tabindex', 6);
      $(this)
        .find('label.LastName')
        .addClass('show');
      $(this)
        .find('label.LastName input')
        .attr('placeholder', 'Last Name')
        .attr('tabindex', 7);
      $(this)
        .find('label.EmailAddress')
        .addClass('show');
      $(this)
        .find('label.EmailAddress input')
        .attr('placeholder', 'Your Email')
        .attr('tabindex', 8);
      $(this)
        .find('label.PostalCode')
        .addClass('show');
      $(this)
        .find('label.PostalCode input')
        .attr('placeholder', 'Zip Code')
        .attr('tabindex', 9);
      $(this)
        .find('.at-form-submit input')
        .attr('tabindex', 10)
        .prop('value', btnText);
    });
    $('.interstitial .ngp-code.add-callback').each(function() {
      const interBtnText = $(this)
        .find('.sr-only')
        .text();
      $(this)
        .find('label.FirstName')
        .addClass('show');
      $(this)
        .find('label.FirstName input')
        .attr('placeholder', 'First Name')
        .attr('tabindex', 1);
      $(this)
        .find('label.LastName')
        .addClass('show');
      $(this)
        .find('label.LastName input')
        .attr('placeholder', 'Last Name')
        .attr('tabindex', 2);
      $(this)
        .find('label.EmailAddress')
        .addClass('show');
      $(this)
        .find('label.EmailAddress input')
        .attr('placeholder', 'Your Email')
        .attr('tabindex', 3);
      $(this)
        .find('label.PostalCode')
        .addClass('show');
      $(this)
        .find('label.PostalCode input')
        .attr('placeholder', 'Zip Code')
        .attr('tabindex', 4);
      $(this)
        .find('.at-form-submit input')
        .attr('tabindex', 5)
        .prop('value', interBtnText);
    });
  };

  // strange & worrisome that this is necessary
  $(window).bind('load', () => {
    setTimeout(ngpCallback, 500);
    setTimeout(ngpCallback, 1000);
    setTimeout(ngpCallback, 2000);
  });
}
