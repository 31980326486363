/* global $ */
function closeInterstitial(e) {
  $('html').removeClass('noScroll overlay-active');
  $('.interstitial').fadeOut();
  e.preventDefault();
  e.stopPropagation();
}

if ($('.interstitial').length > 0) {
  $('body')
    .on('click', '#btn-close', closeInterstitial);

  // close on ESC
  document.addEventListener('keyup', (ev) => ev.keyCode === 27 && closeInterstitial(ev));
}
