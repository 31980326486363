/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $modules = $('body').find('[data-module],.module');

// add class to last module
$modules.last().addClass('last-module');

const checkActiveSections = throttleDomAfterAsync(() => {
  $modules.each((i, el) => {
    const $el = $(el);
    const isVisible = $el.visible(true);
    if (isVisible && !$el.hasClass('seenSection')) {
      $el.addClass('seenSection');
    }
    $el.toggleClass('activeSection', isVisible);
  });
}, scrollEffectDelay);

$(window).on('scroll', checkActiveSections);

// Landing page
$('body')
  .on('mouseenter', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '1');
  })
  .on('mouseleave', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '0');
  })
  .on('click', '.scroll', function(e) {
    e.preventDefault();
    const scrollAnchor = $(this).attr('data-scroll');
    const scrollTop =
      $('.module[data-anchor="' + scrollAnchor + '"]').offset().top - 72;

    $('html, body').animate({ scrollTop }, 500);
  });
